import React from 'react';
import { graphql } from 'gatsby';

import { layout, authorBox, postBody, linkList } from './post-page.module.scss';
import { relations } from '../config/relations';
import { translationKeys } from '../config/translation-keys';
import { ISitePageContext } from '../models/site-page.model';
import { IPost } from '../models/post.model';
import { useTranslation } from '../hooks/use-translation';
import { AuthorsContextProvider } from '../contexts/author-context';
import { useSinglePostCard } from '../contexts/post-cards-context';
import useMedia from '../hooks/use-media';

import ContentLayout from '../layouts/content-layout';
import PostBody from '../components/organisms/post-body';
import Author from '../components/organisms/author';
import SocialShare from '../components/organisms/social-share';
import PostLinks from '../components/organisms/post-links';

interface IPostPageProps {
    readonly pageContext: ISitePageContext;
    readonly data: {
        post: IPost;
    };
}

const PostPage: React.FC<IPostPageProps> = ({ data }) => {
    const { post } = data;
    const {
        author: { profileId },
        articleId,
        links,
    } = post;
    const { title, lead } = useTranslation(post, translationKeys.post);

    const { media, tagsPriority } = useSinglePostCard(articleId) || {};
    const ogImage = useMedia({
        media,
        relation: relations.ogImage,
        fallback: relations.coverImage,
    });
    const mainPostTag = tagsPriority?.find(tagsPriority => tagsPriority.isMain);

    return (
        <AuthorsContextProvider>
            <ContentLayout
                className={layout}
                excludeAsidePostId={articleId}
                postMainTagId={mainPostTag?.tagId}
                SEOProps={{
                    title: title,
                    description: lead,
                    image: ogImage.url,
                    isArticle: true,
                }}
            >
                <PostBody post={post} className={postBody} />
                <SocialShare />
                {links.length > 0 && <PostLinks links={links} className={linkList} />}
                {profileId && <Author profileId={profileId} className={authorBox} />}
            </ContentLayout>
        </AuthorsContextProvider>
    );
};

export const query = graphql`
    query ($language: String!, $articleId: Int!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        post(articleId: { eq: $articleId }) {
            ...postFields
        }
    }
`;

export default PostPage;
