import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, avatar, avatarRatio, info, name, contactLink } from './author.module.scss';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IAuthor } from '../../models/author.model';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';
import { useAuthor } from '../../contexts/author-context';

import RatioImage from '../atoms/ratio-image';
import Markdown from '../hoc/markdown';

interface IAuthorProps {
    className?: string;
    profileId: IAuthor['profileId'];
    NameTag?: React.ElementType;
}

const Author: React.FC<IAuthorProps> = ({ className = '', profileId, NameTag = 'h2' }) => {
    const { t } = useI18next();
    const author = useAuthor(profileId);
    const { media, email } = author || {};
    const { firstName, lastName, bio } = useTranslation(author, translationKeys.author);
    const { image } = useMedia({ media, relation: relations.mainImage });

    return (
        <div className={`${container} ${className}`}>
            <RatioImage
                className={avatar}
                ratioClass={avatarRatio}
                image={image}
                alt={`${firstName} ${lastName}`}
            />
            <div className={info}>
                <NameTag className={name}>
                    {t('author.header')}: {firstName} {lastName}
                </NameTag>
                {bio && <Markdown>{bio}</Markdown>}
                {email && (
                    <p>
                        {t('author.contact')}:{` `}
                        <a href={`mailto:${email}`} className={contactLink}>
                            {email}
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
};

export default Author;
