import React from 'react';

import { container } from './post-meta.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IAuthor } from '../../models/author.model';
import { IPost } from '../../models/post.model';
import { useTranslation } from '../../hooks/use-translation';
import { formatUnixTimestamp } from '../../utils/format-unix-timestamp';
import { useAuthor } from '../../contexts/author-context';

interface IPostMetaProps {
    className?: string;
    profileId: IAuthor['profileId'];
    publishedAt: IPost['publishedAt'];
}

const PostMeta: React.FC<IPostMetaProps> = ({ className = '', profileId, publishedAt }) => {
    const author = useAuthor(profileId);
    const { firstName = '', lastName = '' } = useTranslation(author, translationKeys.author);
    return (
        <p className={`${container} ${className}`}>
            {firstName} {lastName} / {formatUnixTimestamp(publishedAt)} /
        </p>
    );
};

export default PostMeta;
