import React, { createContext, useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { IAuthor } from '../models/author.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { getNodes } from '../utils/get-nodes';

interface IAuthorsContext {
    authors: IAuthor[];
}

interface IAuthorsContextQueryResult {
    allAuthor: IQueryAllResult<IAuthor>;
}

const initialContext: IAuthorsContext = {
    authors: [],
};

const AuthorsContext = createContext(initialContext);

export const AuthorsContextProvider: React.FC = ({ children }) => {
    const { allAuthor }: IAuthorsContextQueryResult = useStaticQuery(query);
    const authors = getNodes(allAuthor);
    return (
        <AuthorsContext.Provider
            value={{
                authors,
            }}
        >
            {children}
        </AuthorsContext.Provider>
    );
};

export const useAuthorsContext = () => {
    const context = useContext(AuthorsContext);

    if (context === undefined) {
        throw new Error('usePostsContext was used outside of its Provider');
    }

    return context;
};

export const useAuthor = (profileId: IAuthor['profileId']) => {
    const { authors } = useAuthorsContext();
    const [author, setAuthor] = useState<IAuthor | undefined>(
        getAuthorByProfileId(profileId, authors)
    );

    useEffect(() => {
        setAuthor(getAuthorByProfileId(profileId, authors));
    }, [profileId]);

    return author;
};

function getAuthorByProfileId(
    profileId: IAuthor['profileId'],
    authors: IAuthor[]
): IAuthor | undefined {
    return authors.find(author => author.profileId === profileId);
}

const query = graphql`
    query {
        allAuthor {
            edges {
                node {
                    ...authorFields
                }
            }
        }
    }
`;
