import React from 'react';

import { contentBox, leadText, meta, titleText } from './post-body.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IPost } from '../../models/post.model';
import { useTranslation } from '../../hooks/use-translation';

import PostMeta from '../molecules/post-meta';
import Markdown from '../hoc/markdown';

interface IPostBodyProps {
    className?: string;
    post: IPost;
}

const PostBody: React.FC<IPostBodyProps> = ({ className = '', post }) => {
    const {
        author: { profileId },
        publishedAt,
    } = post;
    const { titleWithoutOrphans, lead, content } = useTranslation(post, translationKeys.post);

    return (
        <article className={className}>
            <h1 className={titleText}>{titleWithoutOrphans}</h1>
            <PostMeta profileId={profileId} publishedAt={publishedAt} className={meta} />
            {lead && <Markdown className={leadText}>{`<strong>${lead}</strong>`}</Markdown>}
            {content && <Markdown className={contentBox}>{content}</Markdown>}
        </article>
    );
};

export default PostBody;
