import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { header, list, link } from './post-links.module.scss';

interface IPostLinksProps {
    className?: string;
    links: string[];
    HeaderTag?: React.ElementType;
}

const PostLinks: React.FC<IPostLinksProps> = ({ className = '', HeaderTag = 'h2', links }) => {
    const { t } = useI18next();
    return (
        <div className={className}>
            <HeaderTag className={header}>{t('post.links.header')}</HeaderTag>
            <ul className={list}>
                {links.map(href => {
                    return (
                        <li key={href}>
                            <a
                                className={link}
                                title={href}
                                href={href}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {href}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default PostLinks;
