import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { FacebookShareButton, FacebookMessengerShareButton } from 'react-share';
import { useLocation } from '@reach/router';

import { container, open, shareButton, buttons } from './social-share.module.scss';

import IconButton from '../atoms/icon-button';

interface ISocialShareProps {
    className?: string;
}

const SocialShare: React.FC<ISocialShareProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { href } = useLocation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className={className}>
            <div className={`${container} ${isOpen ? open : ''}`}>
                <IconButton
                    icon="share"
                    color={isOpen ? 'accent' : 'dark'}
                    disableDefaultHover={true}
                    className={shareButton}
                    label={t('share.show')}
                    onClick={() => setIsOpen(!isOpen)}
                />
                <div className={buttons}>
                    <FacebookMessengerShareButton
                        url={href}
                        aria-label={t('share.messenger')}
                        title={t('share.messenger')}
                        appId={process.env.FACEBOOK_APP_ID || ''}
                    >
                        <IconButton icon="messenger" color="dark" as="element" />
                    </FacebookMessengerShareButton>
                    <FacebookShareButton
                        url={href}
                        aria-label={t('share.facebook')}
                        title={t('share.facebook')}
                    >
                        <IconButton icon="facebook" color="dark" as="element" />
                    </FacebookShareButton>
                </div>
            </div>
        </div>
    );
};

export default SocialShare;
